<template>
  <v-form>
    <v-row align="center" justify="center" class="pa-4">
      <v-col cols="12">
        <h1 style="font-weight: 800; color: #e53935">จัดการสินค้า</h1>
      </v-col>
      <v-card outlined width="100%">
        <v-row>
          <v-col cols="12" md="4" sm="4">
            <v-col cols="12" md="12" sm="12"
              ><span>เลือกหมวดหมู่ของสินค้า</span>
              <span style="color: red">*</span>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-autocomplete
                v-model="category"
                :items="itemsCategory"
                item-text="category"
                item-value="id"
                placeholder="เลือกหมวดหมู่สินค้า"
                outlined
                dense
              ></v-autocomplete
            ></v-col>
          </v-col>
          <!-- <v-col cols="12" md="4" sm="4">
            <v-col cols="12" md="12" sm="12"
              ><span>เลือกประเภทของสินค้า</span>
              <span style="color: red">*</span>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-autocomplete
                v-model="type"
                :items="itemsType"
                item-text="type"
                item-value="id"
                placeholder="เลือกประเภทสินค้า"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
          </v-col> -->
          <v-col cols="12" md="4" sm="4">
            <v-col cols="12" md="12" sm="12"
              ><span>เลือกยี่ห้อของสินค้า</span>
              <span style="color: red">*</span>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-autocomplete
                v-model="brand"
                :items="itemsBrand"
                item-text="brandName"
                item-value="id"
                placeholder="เลือกยี่ห้อสินค้า"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
          </v-col>
          <!-- </v-row>
        <v-row> -->
          <v-col cols="12" md="4" align="center" justify="center">
            <v-col cols="12" md="12" sm="12"> </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn class="ma-4">เคลียร์</v-btn>
              <v-btn class="ma-4" color="primary" @click="getAllProduct()"
                >ค้นหา</v-btn
              >
            </v-col>
          </v-col>
        </v-row>
      </v-card>
      <v-col cols="12">
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <v-btn @click="goToCreate()" color="primary" dark
              >เพิ่มสินค้า</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headersproduct"
            :items="items"
            :search="search"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:[`item.imgUrl`]="{ item }">
              <v-img :src="item.imgUrl" width="100px" class="ma-1"></v-img>
            </template>

            <template v-slot:[`item.activeFlag`]="{ item }">
              <v-chip class="orange lighten-2" v-if="item.activeFlag == true"
                >ใช้งาน</v-chip
              >
              <v-chip
                class="grey lighten-9 white--text"
                v-if="item.activeFlag == false"
                >ไม่ใช้งาน</v-chip
              >
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                <v-icon @click="viewProduct(item)">mdi-eye</v-icon>
                <v-icon @click="UpdateProduct(item)" class="mx-2"
                  >mdi-pencil</v-icon
                >
                <v-icon @click="DeleteProduct(item)">mdi-delete</v-icon>
              </v-row>
            </template>
          </v-data-table></v-card
        >
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import draggable from "vuedraggable";
import { Encode, Decode } from "@/services";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      headersproduct: [
        // { text: "", value: "", align: "center", width: "50" },

        { text: "ลำดับ", value: "count", align: "center", width: "100" },

        {
          text: "รูปกลุ่มสินค้า",
          value: "imgUrl",
          align: "center",
          width: "200",
        },
        {
          text: "ชื่อกลุ่มสินค้า",
          value: "groupName",
          align: "center",
          width: "100",
        },
        {
          text: "ยี่ห้อกลุ่มสินค้า",
          value: "brand.brandName",
          align: "center",
          width: "200",
        },
        {
          text: "หมวดหมู่กลุ่มสินค้า",
          value: "category.category",
          align: "center",
          width: "200",
        },
        { text: "สถานะ", value: "activeFlag", align: "center", width: "100" },
        { text: "ตัวเลือก", value: "action", align: "center", width: "120" },
      ],
      category: "",
      itemsCategory: [],
      type: "",
      itemsType: [],
      brand: "",
      itemsBrand: [],
      // activeFlag: (...)
      // brandId: (...)
      // categoryId: (...)
      // count: 1
      // createdAt: (...)
      // description: (...)
      // id: (...)
      // imgUrl: (...)
      // price: (...)
      // productName: (...)
      // sku: (...)
      // typeId: (...)
      // unit: (...)
      // updatedAt: (...)
      items: [],
      search: "",
      count: 0,
    };
  },
  created() {
    this.dropdown();
    this.getAllProduct();
    for (let i in this.items) {
      this.items[i].count = parseInt(i) + 1;
      //console.log(this.list);
    }
  },

  methods: {
    async dropdown() {
      const cateDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/categories`
      );
      // const typeDropdown = await this.axios.get(
      //   `${process.env.VUE_APP_API}/types`
      // );
      const brandDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/brands`
      );
      const companyDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/companies`
      );
      this.itemsCategory = cateDropdown.data.data;
      // this.itemsType = typeDropdown.data.data;
      this.itemsBrand = brandDropdown.data.data;
      this.itemsCompany = companyDropdown.data.data;
      console.log("companyDropdown", this.itemsCompany);
    },
    async getAllProduct() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/products/findAllProductGroup?brandId=` +
          this.brand +
          "&typeId=" +
          this.type +
          "&categoryId=" +
          this.category
        // 'http://localhost:8081/types/'
      );
      console.log("getAllproducts", response.data.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
    },

    goToCreate() {
      this.$router.push("createproduct");
    },
    viewProduct(val) {
      localStorage.setItem("productdata", Encode.encode(val));
      this.$router.push("viewProduct");
    },
    UpdateProduct(val) {
      localStorage.setItem("productdata", Encode.encode(val));
      this.$router.push("EditProduct");
    },
    async DeleteProduct(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var user = JSON.parse(Decode.decode(localStorage.getItem("userSPM")));
          const auth = {
            headers: { Authorization: `Bearer ${user.token}` },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/products/deleteProductGroup/` + val.id,
            // `${process.env.VUE_APP_API}/products/` + val.id,
            auth
          );
          console.log(response);
          if (response.data.response_status == "ERROR") {
            this.$swal.fire({
              icon: "error",
              text: response.data.message,
              showConfirmButton: false,
              timer: 2000,
            });
          } else {
            this.$swal.fire({
              icon: "success",
              text: "ลบข้อมูลสำเร็จ",
              showConfirmButton: false,
              timer: 1000,
            });
            // await location.reload();
            this.getAllProduct();
          }
        }
      });
    },
  },
};
</script>
